<template>
  <validation-observer ref="simpleRules">
    <component>

      <b-card v-if="session > 0">

        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Edit Approval Sales
          </h4>
        </div>

        <!-- Form: Personal Info Form -->
        <b-form class="mt-1">
          <b-row>

            <!-- Field: Bisnis Unit -->
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
               <validation-provider
              #default="{ errors }"
              name="Bisnis Unit"
              rules="required"
            >
              <b-form-group
                label="Bisnis Unit"
                label-for="bu"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.app_div_bu_bu"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="buOptions"
                :clearable="false"
                input-id="bu"
                placeholder="Pilih Bisnis Unit"
                @input="changedValue()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>

             <!-- Field: Divisi -->
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <validation-provider
              #default="{ errors }"
              name="Divisi"
              rules="required"
            >
              <b-form-group
                label="Divisi"
                label-for="divisi"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.app_div_name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="divOptions[0]"
                :clearable="false"
                input-id="divisi"
                placeholder="Pilih Divisi"
                @input="changedValueSegmen()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <validation-provider
              #default="{ errors }"
              name="Segmen"
              rules="required"
            >
              <b-form-group
                label="Segmen"
                label-for="segmen"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.segmen_name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="segOptions[0]"
                :clearable="false"
                input-id="segmen"
                placeholder="Pilih Segmen"
                @input="changedValueUser()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>

             <!-- Field: User Approval -->
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
            <validation-provider
              #default="{ errors }"
              name="User Approval"
              rules="required"
            >
              <b-form-group
                label="User Approval"
                label-for="user app"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.app_map_user_pk"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="namaOptions[0]"
                :clearable="false"
                input-id="nama"
                placeholder="Pilih User Approval"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>

            <!-- Field: Level -->
            <b-col
              cols="12"
              md="2"
              lg="2"
            >
            <validation-provider
              #default="{ errors }"
              name="Level"
              rules="required"
            >
              <b-form-group
                label="Level"
                label-for="level"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.app_level"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="levelOptions"
                :clearable="false"
                input-id="nama"
                placeholder="Pilih Level"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>
          
          <!-- Field: Batas Bawah -->
            <b-col
              cols="12"
              md="2"
              lg="2"
            >
              <b-form-group
                label="Batas Bawah"
                label-for="batas_bawah"
              >
              <validation-provider
                #default="{ errors }"
                name="Batas Bawah"
                rules="required"
              >
                <b-form-input
                  id="so_code"
                  v-model="userDataInfo.app_batas_bawah"
                  type="number"
                  placeholder="input Batas Bawah"
                  :state="errors.length > 0 ? false:null"
                />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Batas Atas -->
            <b-col
              cols="12"
              md="2"
              lg="2"
            >
              <b-form-group
                label="Batas Atas"
                label-for="batas_atas"
              >
              <validation-provider
                #default="{ errors }"
                name="Batas Atas"
                rules="required"
              >
                <b-form-input
                  id="so_code"
                  v-model="userDataInfo.app_batas_atas"
                  type="number"
                  placeholder="input Batas Atas"
                  :state="errors.length > 0 ? false:null"
                />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Aktif -->
            <b-col
              cols="12"
              md="2"
              lg="2"
            >
              <b-form-group
                label="Aktif"
                label-for="aktif"
                label-class="mb-1"
              >
                <b-form-radio-group
                  id="menu_active"
                  v-model="userDataInfo.app_status"
                  :options="aktifOptions"
                  value=""
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>

              <b-button
                variant="outline-secondary"
                :to="{ name: 'apps-approval-sales-list'}"
              >
                Back
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>

    </component>
  </validation-observer>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  codeVueMultiBasic, codeVueMultiIcon, codeMultiPush, codeVueSelectionLimit, codeVueDir,
} from './code'
import { codeVariant } from './codetoast'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      emailValue: '',
      name: '',
      required,
      email,
      // divOptions: [],
      // namaOptions: [],
      // segOptions: []
    }
  },
  methods: {
    async changedValue() {
      const selectedd = JSON.stringify(this.userDataInfo.app_div_bu_bu)
            console.log(selectedd)
      const result = await axios.post(`${useAppConfig().AppUrl}global/listdivisibybu`,  { id_bu: selectedd })
      const dataDiv = result.data[0].divisi_by_bu
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataDiv.length; i < len; i++) {
        combo.push({ label: dataDiv[i].div_desc, value: dataDiv[i].div_name })
      }
      this.divOptions = []
      this.divOptions.push(combo)
  },
  async changedValueSegmen() {
      const selectedBU = JSON.stringify(this.userDataInfo.nama_bu)
      const selectedDiv = JSON.stringify(this.userDataInfo.div_desc)
      // alert(selectedd);
      const resultSeg = await axios.post(`${useAppConfig().AppUrl}global/listsegmenbybu`,  { id_bu: selectedBU, div_name: selectedDiv })
      const dataSeg = resultSeg.data[0].divisi_by_bu
      // console.log(dataSeg)
      const comboSeg = []
      for (let i = 0, len = dataSeg.length; i < len; i++) {
        comboSeg.push({ label: dataSeg[i].segmen_code_name, value: dataSeg[i].segmen_code })
      }
      this.segOptions = []
      this.segOptions.push(comboSeg)
    },
  async changedValueUser() {
      // const selectedd1 = JSON.stringify(this.userDataInfo.app_div_bu_bu)
      // const selectedd = JSON.stringify(this.userDataInfo.app_div_name)
      // console.log(JSON.stringify(this.userDataInfo.app_div_bu_bu))
      // const result = await axios.post(`${useAppConfig().AppUrl}global/listUserApprovalByBUDivisi`,  { div_name: selectedd, div_bu:selectedd1})
      const selectedd1 = JSON.stringify(this.userDataInfo.nama_bu)
      const selectedd = JSON.stringify(this.userDataInfo.div_desc)
      const selectedSeg = JSON.stringify(this.userDataInfo.segmen_code)
      console.log(selectedd1)
      const result = await axios.post(`${useAppConfig().AppUrl}mst_approval_sales/listUserApproval`,  { segmen_code: selectedSeg, div_name: selectedd, div_bu:selectedd1})
      const dataName = result.data[0].appvsales_by_budiv
      console.log(result)
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataName.length; i < len; i++) {
        combo.push({ label: dataName[i].nik_nama, value: dataName[i].map_user_pk })
      }
      this.namaOptions = []
      this.namaOptions.push(combo)
  },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const params = new URLSearchParams()
          params.append('param', 'update_approval_sales')
          params.append('app_map_user_pk', Array.isArray(this.userDataInfo.app_map_user_pk) ? JSON.stringify(this.userDataInfo.app_map_user_pk[0]) : JSON.stringify(this.userDataInfo.app_map_user_pk))
          params.append('app_map_user_pk_old', this.userDataInfo.app_map_user_pk_old)
          params.append('app_div_bu_bu', JSON.stringify(this.userDataInfo.app_div_bu_bu))
          params.append('app_div_bu_old', this.userDataInfo.app_div_bu_old)
          params.append('app_div_name', JSON.stringify(this.userDataInfo.app_div_name))
          params.append('app_div_name_old', this.userDataInfo.app_div_name_old)
          params.append('app_segmen_code_old', this.userDataInfo.app_segmen_code)
          params.append('app_segmen_code', JSON.stringify(this.userDataInfo.segmen_name))
          params.append('app_level', JSON.stringify(this.userDataInfo.app_level))
          params.append('app_level_old',this.userDataInfo.app_level_old)
          params.append('app_batas_atas', this.userDataInfo.app_batas_atas)
          params.append('app_batas_bawah', this.userDataInfo.app_batas_bawah)
          params.append('app_status', this.userDataInfo.app_status)
          params.append('modify_by', localStorage.getItem('emp_id'))
          const result = await axios.post(`${useAppConfig().AppUrl}mst_approval_sales/update`, params)

          if (result.data.message === 'Berhasil mengubah data') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Berhasil Disimpan',
                variant: 'success',
              },
            })
            this.$router.push('/apps/approval-sales/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Gagal Disimpan',
                variant: 'danger',
              },
            })
            console.log(result)
          }
        }
      })
    },
  },
  setup() {
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })

    const userDataInfo = ref({})
    const divOptions = ref([])
    const segOptions = ref([])
    const namaOptions = ref([])
      onMounted(async () => {
        // const result = await axios.post(`${useAppConfig().AppUrl}mst_approval_sales/edit`, {app_map_user_pk: router.currentRoute.params.app_map_user_pk})
        const paramsEdit = new URLSearchParams()
              paramsEdit.append('app_map_user_pk', router.currentRoute.params.app_map_user_pk)
              paramsEdit.append('app_div_bu', router.currentRoute.params.app_div_bu)
              paramsEdit.append('app_div_name', router.currentRoute.params.app_div_name)
              paramsEdit.append('app_segmen_code', router.currentRoute.params.app_segmen_code)
              paramsEdit.append('app_level', router.currentRoute.params.app_level)
        const result = await axios.get(`${useAppConfig().AppUrl}mst_approval_sales/edit?${paramsEdit}`)
        userDataInfo.value = result.data[0]

        // console.log(userDataInfo.value.app_div_bu_bu);
        const selectedd = JSON.stringify(userDataInfo.value.app_div_bu_bu)
        const resultDiv = await axios.post(`${useAppConfig().AppUrl}global/listdivisibybu`,  { id_bu: selectedd })
        const dataDiv = resultDiv.data[0].divisi_by_bu
        // console.log(dataDiv)
        const combo = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = dataDiv.length; i < len; i++) {
          combo.push({ label: dataDiv[i].div_desc, value: dataDiv[i].div_name })
        }
        // this.divOptions = []
        divOptions.value = [combo]

        const selectedDiv = JSON.stringify(userDataInfo.value.app_div_name)
        const resultSeg = await axios.post(`${useAppConfig().AppUrl}global/listsegmenbybu`,  { id_bu: selectedd, div_name: selectedDiv })
        const dataSeg = resultSeg.data[0].data
        // console.log(dataSeg)
        const comboSeg = []
        // console.log(dataSeg);
        for (let i = 0, len = dataSeg.length; i < len; i++) {
          comboSeg.push({ label: dataSeg[i].segmen_code_name, value: dataSeg[i].segmen_code })
        }
        // this.segOptions = []
        segOptions.value = [comboSeg]

        const selectedSeg = JSON.stringify(userDataInfo.value.segmen_name)
        const resultUser = await axios.post(`${useAppConfig().AppUrl}mst_approval_sales/listUserApproval`,  { segmen_code: selectedSeg, div_name: selectedDiv, div_bu:selectedd})
        const dataName = resultUser.data[0].appvsales_by_budiv
        // console.log(result)
        const comboUser = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = dataName.length; i < len; i++) {
          comboUser.push({ label: dataName[i].nik_nama, value: dataName[i].map_user_pk })
        }
        // this.namaOptions = []
        namaOptions.value = [comboUser]
    })

    const buOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AppUrl}global/listCombo`)
      const dataBisnisunit = result.data[0].bu
      const combo = []
      // eslint-disable-next-line no-plusplus
       for (let i = 0, len = dataBisnisunit.length; i < len; i++) {
        combo.push({ label: dataBisnisunit[i].nama_bu, value: dataBisnisunit[i].id_bu })
      }
      buOptions.value = combo
    }) 

    const aktifOptions = [
      { text: 'Ya', value: 1 },
      { text: 'Tidak', value: 0 },
    ]

    const levelOptions = [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
    ]

    return {
      session,
      codeVueMultiBasic,
      codeVueMultiIcon,
      codeMultiPush,
      codeVueSelectionLimit,
      codeVueDir,
      aktifOptions,
      levelOptions,
      userDataInfo,
      buOptions,
      codeVariant,
      divOptions,
      segOptions,
      namaOptions
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>

</style>
